<template>
  <v-card
    :style="smUp ? 'background-color: transparent; padding-top: 20px; margin-bottom: 50px':''"
    flat>
      <v-layout
        :style="smUp ? 'max-width: 85%; margin-top: 2.5%; margin-bottom: 4%': 'margin-top: 2.5%; margin-bottom: 6%'"
        wrap
        row
        :justify-center="!smUp"
        :justify-start="smUp">
        <v-flex
          xs12
          sm6>
          <v-layout
            row
            :justify-center="!smUp"
            :justify-start="smUp">
            <p
              v-text="'Reports'"
              class="view_title_text"
            />
          </v-layout>
        </v-flex>
        <v-flex
          xs12
          sm6>
          <v-layout
            row
            :justify-center="!smUp"
            :justify-end="smUp">
            <v-btn
              @click="add_report = true"
              style="margin: 20px"
              color="primary"
              dense
              >
              Add
            </v-btn>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout
        row
        justify-center>
        <v-layout
          style="margin-left: -24px;"
          wrap
          row
          :class="smUp  ? 'search_user':'search_user_mobile'">
          <v-flex
            xs12
            sm6
            md6
            :class="smUp  ? 'fieldsFlex':'fieldsFlexMobile'">
            <v-text-field
              class="customPlaceHolder"
              append-icon="mdi-magnify"
              solo
              clearable
              v-model="report_search"
              color="primary"
              label="Search reports"
            />
          </v-flex>
          <v-flex
            xs12
            sm3
            md3
            :class="smUp  ? 'fieldsFlex':'fieldsFlexMobile'">
            <v-select
              :items="yearOptions"
              v-model="yearSelected"
              label="Year"
              class="customPlaceHolder"
              solo
              clearable>
              <template v-slot:no-data>
                <p
                  class="selectNoData">
                  No years available for selection.
                </p>
              </template>
              <template 
                slot="item" 
                slot-scope="data">
                <v-layout
                  row
                  justify-center>
                  <p class="selectItem">
                    {{data.item}}
                  </p>
                </v-layout>
              </template>
            </v-select>
          </v-flex>
          <v-flex
            xs12
            sm3
            md3
            :class="smUp  ? 'fieldsFlex':'fieldsFlexMobile'">
            <v-select
              :items="monthOptions"
              v-model="monthSelected"
              label="Month"
              class="customPlaceHolder"
              solo
              clearable>
              <template v-slot:no-data>
                <p
                  class="selectNoData">
                  No months available for selection.
                </p>
              </template>
              <template 
                slot="item" 
                slot-scope="data">
                <v-layout
                  row
                  justify-center>
                  <p class="selectItem">
                    {{data.item.text}}
                  </p>
                </v-layout>
              </template>
            </v-select>
          </v-flex>
        </v-layout>
      </v-layout>
      <v-layout>
        <v-container>
          <!-- Start table container -->
          <div
            v-if="!bLoading">
            <reports-table 
              :items="reports_list"
              @update="get_reports"
            />
            <v-pagination 
              v-model="current_page" 
              :length="num_pages">
            </v-pagination>
          </div>
          <!-- Loading animation -->
          <v-card
            flat
            style="height: 100%; width: 100%; background-color: transparent"
            v-else>
            <v-layout
              :style="smUp ? 'max-width: 90%; margin-top: 10%':''"
              row
              justify-center>
              <v-progress-circular
                indeterminate
                rotate
                color="primary"
                size="100"
                width="1"
              />
            </v-layout>
          </v-card>
          <!-- Loading animation -->
          <!-- End table container -->
        </v-container>
      </v-layout>
      <reports-add
        :bActive="add_report"
        @close="add_report = false"
        @update="get_reports"
      />
  </v-card>
</template>
<script>
export default {
  data: () => ({  
    yearOptions: [],
    monthOptions: [
      {
        text: 'January',
        value: 0,
      },
      {
        text: 'February',
        value: 1,
      },
      {
        text: 'March',
        value: 2,
      },
      {
        text: 'April',
        value: 3,
      },
      {
        text: 'May',
        value: 4,
      },
      {
        text: 'June',
        value: 5,
      },
      {
        text: 'July',
        value: 6,
      },
      {
        text: 'August',
        value: 7,
      },
      {
        text: 'September',
        value: 8,
      },
      {
        text: 'October',
        value: 9,
      },
      {
        text: 'November',
        value: 10,
      },
      {
        text: 'December',
        value: 11,
      }
    ],
    current_page: 1,
    num_pages: 0,
    reports_list: [],
    add_report: false,
    bLoading: false, 
    report_search: '',
    monthSelected: null,
    yearSelected: null
  }),
  mounted () {
    this.get_reports()
    this.fillYears()
  },
  watch: {
    monthSelected: function () {
      if ((this.monthSelected || this.yearSelected || this.report_search) && !this.bLoading) {
        this.get_reports_search()
      } else if (!this.bLoading) {
        this.get_reports()
      } 
    },
    yearSelected: function () {
      if ((this.monthSelected || this.yearSelected || this.report_search) && !this.bLoading) {
        this.get_reports_search()
      } else if (!this.bLoading) {
        this.get_reports()
      } 
    },
    current_page: function () {
      if ((this.monthSelected || this.yearSelected || this.report_search) && !this.bLoading) {
        this.get_reports_search()
      } else if (!this.bLoading) {
        this.get_reports()
      } 
    },
    report_search: _.debounce(function() {
      if ((this.monthSelected || this.yearSelected || this.report_search) && !this.bLoading) {
        this.get_reports_search()
      } else if (!this.bLoading) {
        this.get_reports()
      }    
    }, 500)
  },
  methods: {
    fillYears () {
      var currentYear = new Date().getFullYear()
      var startYear = 2020
      while ( startYear <= currentYear ) {
          this.yearOptions.push(startYear++);
      }   
    },
    get_reports () {
      this.bLoading = true
      db.get(`${uri}/v2/api/reports`, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
          'Authorization': this.usr_token
        },
        params: {
          iPage: this.current_page,
          iPageSize: 15
        }
      })
      .then(resp => {
        this.bLoading = false
        this.$store.commit('toggle_alert', {color: 'success', text: resp.data.message})
        this.num_pages = resp.data.iTotalPages
        this.reports_list = resp.data.reports
      })
      .catch(err => {
        this.bLoading = false
        this.$store.commit('toggle_alert', {color: 'fail', text: err.response.data.message})
      })
    },
    get_reports_search () {
      this.bLoading = true
      db.get(`${uri}/v2/api/reports`, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
          'Authorization': this.usr_token
        },
        params: {
          iPage: this.current_page,
          iPageSize: 15,
          iMonth: this.monthSelected,
          iYear: this.yearSelected,
          query: this.report_search
        }
      })
      .then(resp => {
        this.bLoading = false
        this.$store.commit('toggle_alert', {color: 'success', text: resp.data.message})
        this.num_pages = resp.data.iTotalPages
        this.reports_list = resp.data.reports
      })
      .catch(err => {
        this.bLoading = false
        this.$store.commit('toggle_alert', {color: 'fail', text: err.response.data.message})
      })
    }
  },
  computed: {
    smUp () {
      return this.$vuetify.breakpoint.smAndUp
    },
    usr_token () {
      return 'Bearer ' + this.$store.state.token
    }
  },
  components: {
    ReportsAdd: () => import('@/components/sections/Admin/Reports/ReportsAdd'),
    ReportsTable: () => import('@/components/sections/Admin/Reports/ReportsTable')
  }
}
</script>
<style>
  .customPlaceHolder .v-label {
    font-family: "Open sans", sans-serif !important;
    font-size: 11px !important;
    font-weight: bold !important;
    font-style: italic !important;
    margin-left: 6px !important;
    margin-bottom: 0px !important;
    color: rgba(0, 0, 0, 0.568) !important;
  }
</style>
<style scoped>
.selectNoData {
  margin: 10px;
  font-family: 'Open sans', sans-serif;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
}
.selectItem {
  font-family: "Open sans", sans-serif !important;
  font-size: 11px !important;
  font-weight: bold !important;
  margin-left: 6px !important;
  margin-bottom: 0px !important;
  color: rgba(0, 0, 0, 0.568) !important;
  text-align: center !important;
}
.fieldsFlex {
  padding-left: 4px;
  padding-right: 4px
}
.total_user_text {
  font-family: "Open sans", sans-serif; 
  font-size: 15px; 
  margin-right: 10%
}
.total_user_text_mobile {
  max-width: 90%;
  text-align: center;
  font-family: "Open sans", sans-serif; 
  font-size: 10px; 
}
.search_user {
  margin-right: 7%
}
.search_user_mobile {
  max-width: 80%
}
</style>